body {
  margin: 0;
  font-family: "Roboto", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px !important;
  zoom: 0;
}
header,
main {
  padding-left: 300px;
}

@media only screen and (max-width: 992px) {
  header,
  main {
    padding-left: 0;
  }
}
