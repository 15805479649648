body {
  overflow-x: hidden;
}

body .dashboard .container {
  max-width: fit-content;
}

#sidebar-wrapper {
  min-height: 100vh;
  margin-left: -15rem;
  -webkit-transition: margin 0.25s ease-out;
  -moz-transition: margin 0.25s ease-out;
  -o-transition: margin 0.25s ease-out;
  transition: margin 0.25s ease-out;
}

#sidebar-wrapper .sidebar-heading {
  padding: 0.875rem 1.25rem;
  font-size: 1.2rem;
}

#page-content-wrapper {
  min-width: 100vw;
}

#wrapper.toggled #sidebar-wrapper {
  margin-left: 0;
}

/* .as-react-table .col-md-12{
    overflow-x: auto;
} */

@media (min-width: 768px) {
  #sidebar-wrapper {
    margin-left: 0;
  }

  #page-content-wrapper {
    min-width: 0;
    width: 100%;
  }

  #wrapper.toggled #sidebar-wrapper {
    margin-left: -10rem;
  }
}
@media (max-width: 768px) {
  #main {
    margin-left: 60px !important;
  }
  body .mobile-float-left {
    float: left !important;
    margin-top: 5px;
  }
  body h2 {
    font-size: 28px !important;
  }
  body h1 {
    font-size: 40px !important;
  }
}

#as-react-datatable-container select {
  width: 75px !important;
}

.react-autosuggest__suggestions-container {
  position: absolute;
  z-index: 2;
  background: #fff;
  padding: 10px 20px 0px 0px;
  border: 1px solid #e2e2e2;
}

.react-autosuggest__suggestion {
  list-style: none;
  margin: 6px -10px;
  border-bottom: 1px solid #e2e2e2;
  cursor: pointer;
}

.autocomplete_box input {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.autocomplete_box > div > div {
  z-index: 99;
  padding: 2px 10px;
}

.autocomplete_box > div {
  width: 100%;
}

.loading-wrapper {
  width: 100%;
  height: 100%;
  position: fixed;
  background: #e2e2e2;
  z-index: 99999;
  opacity: 0.4;
  left: 0;
  top: 0;
}

.loading-wrapper > span {
  position: absolute;
  top: 45%;
  left: 50%;
  z-index: 999;
}

.table th,
.table td {
  padding: 0.4rem !important;
}
.form-group.col-md-12.alphabet span {
  padding: 2px;
  color: #007bff;
  font-weight: bold;
}
.as-react-table .asrt-page-length {
  display: initial;
}
.as-react-table .col-md-12 {
  padding-top: 26px;
}
.advanceSearch {
  width: 500px !important ;
}
.advanceSearch input {
  width: 300px;
  float: left;
  margin-left: 20px;
}
.advanceSearch button {
  width: 75px;
  float: left;
  margin-left: 5px;
}
.advanceSearch button1 {
  width: 125px;
  float: left;
  margin-left: 5px;
}
body .navbar {
  padding: 0.25rem 1rem;
  border-bottom: 1px solid rgba(173, 173, 173, 0.4);
}
/* .Sidebar{
    background-color: #2A3E90;
} */
.float-left {
  float: left;
}

.float-right {
  float: right;
}
.float-right-padding {
  padding-left: 333px;
  padding-top: 3px;
}
.selected {
  color: rgb(61, 6, 6) !important;
}
.card.shadow-lg .map {
  position: relative !important;
}

a.list-group-item.list-group-item-action,
.navbar-brand {
  background-color: #1d2127;
  color: #abb4be;
  border: 1px solid rgba(225, 225, 225, 0.125);
}
a.list-group-item.list-group-item-action:hover {
  color: #fff;
}
div#sticky-sidebar {
  z-index: 999;
}
body .bg-dark {
  background-color: #1d2127 !important;
}

footer .container.p-4.pb-0 {
  padding: 0 !important;
  margin-top: 10px;
}

i.fa.fa-calendar {
  position: absolute;
  right: 10px;
  font-size: 18px;
  bottom: 10px;
}

.list-group.list-group-flush .navbar-brand {
  padding: 8px 3px;
  background: #fff;
  margin-right: 0;
}
.list-group.list-group-flush .navbar-brand img {
  width: 200px;
}
#sidebar-wrapper .svg-inline--fa {
  margin-right: 10px;
}

.group-list button.btn.btn-outline-primary.float-right.mt-3.mr-2.a,
.group-list .table_tools {
  margin-right: 210px !important;
}

.reports button.btn.btn-outline-primary.float-right.mt-3.mr-2,
.reports .table_tools {
  margin-right: 250px !important;
}
.form-group.col-md-3.text-center.py-4.px-4.font-weight-bold.text-white {
  font-size: 16px;
}

.form-group.col-md-12.alphabet {
  text-align: center;
  font-size: 16px;
}

a.list-group-item.list-group-item-action,
.navbar-brand {
  background-color: initial;
  border: initial;
}
.sidebar-btn-wrapper.logout {
  cursor: pointer;
}
body .pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item {
  padding: 4px 15px 4px 10px;
}
body
  .pro-sidebar
  .pro-menu
  .pro-menu-item.pro-sub-menu
  .pro-inner-list-item
  .pro-inner-item {
  padding: 4px 15px 4px 15px;
}

.pro-sidebar.collapsed .pro-sidebar-header svg {
  margin: 10px 18px !important;
}
.pro-sidebar .pro-sidebar-header svg {
  font-size: 25px;
  cursor: pointer;
  margin-bottom: -5px;
  color: #1d2127;
}
.row .pro-sidebar {
  position: fixed !important;
  min-width: 250px;
  width: 250px;
  height: calc(100vh);
}
.pro-sidebar .pro-sidebar-header img {
  width: 150px;
  margin: 10px 20px;
}

.pro-sidebar-header {
  background-color: #fff;
}
body .pro-sidebar.collapsed {
  width: 60px;
  min-width: 60px;
}
a.list-group-item.list-group-item-action {
  padding-left: 0 !important;
}
.collapsed .pro-sidebar-footer span.pro-item-content {
  background: #2b2b2b;
}

.collapsed .pro-sidebar-footer a.list-group-item.list-group-item-action {
  padding: 3px !important;
}
.collapsed
  .pro-sidebar-footer
  a.list-group-item.list-group-item-action
  .logout-text {
  display: none;
}
#add-report-modal i.fa.fa-calendar {
  right: 20px;
}

.age-card .left {
  float: left;
}
.age-card .right {
  text-align: right;
}
.card-body .left {
  float: left;
}
.card-body .right {
  text-align: right;
}
.card-body .col-md-6.mr-4.text-center.py-2.px-4 {
  float: left;
  background: #dc3b2d;
}

th.non-sortable.asc.text-left {
  background-image: none !important;
}

.alererx svg.svg-inline--fa {
  width: 22px;
}
.alererx .list-group-items {
  list-style: none;
}

#pills-tab-login .nav-item {
  width: 50%;
  text-align: center;
}

.static-image {
  opacity: 0.3;
  width: 100%;
}

.static-card {
  position: relative;
  text-align: center;
}

.static-card .card {
  position: absolute;
  z-index: 9;
  margin: 25% 18% 0% 33%;
}
.sc-ifAKCX.jZkBQk {
  z-index: 999;
}

.mme-calculator tr:nth-child(even) {
  background-color: #e2e2e2;
}

table.table.table-bordered.table-striped.mme-calculator {
  width: 70%;
  margin: 0 auto;
}

.mme-calculator p {
  color: #5d6165;
}

body .btn-primary,
body .btn-primary:hover,
body .nav-pills .nav-link.active,
body .nav-pills .show > .nav-link {
  background-color: #2a3e90;
  border-color: #2a3e90;
}
body a,
body th.sortable,
body .btn-outline-primary {
  color: #2a3e90;
}
body .bg-primary {
  background-color: #2a3e90 !important;
}

#pills-tab li.nav-item {
  border: 2px solid #2a3e90;
}
h1.mt-2.text-primary {
  color: #393838 !important;
}
button.btn.btn-secordry {
  border: 2px solid #c2c0c0;
  margin: 0 5px;
}
.BiennialForm {
  margin: 0 auto;
  font-size: 18px;
}
.center {
  text-align: center;
}
.left {
  text-align: left !important;
}
.right {
  text-align: right !important;
}
.float-right {
  float: right !important;
}
.nam {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;
}
.p-3 {
  padding: 1rem !important;
}
.add-pad {
  padding-left: 65px !important;
}
.modal i.fa.fa-calendar {
  right: 20px;
  bottom: 30px;
}

table#data-table tr.tr-red,
.info .color-red {
  color: red;
}
table#data-table tr.tr-orange,
.info .color-orange {
  color: #ff5d1f;
}
table#data-table tr.tr-purple,
.info .color-purple {
  color: purple;
}
table#data-table tr.tr-green,
.info .color-green {
  color: green;
}
p.help-text.font-weight-bold.h6 {
  font-size: 18px;
}
#add-purchase-modal i.fa.fa-calendar #add-comparerx-modal {
  bottom: 12px;
}
td.email.email-blurr {
  color: transparent;
  text-shadow: 0 0 5px rgb(0 0 0 / 70%);
}
img.sc-dkPtRN.bgJYxZ {
  margin-bottom: 5px;
}
.sc-gsDKAQ.bsIdxO {
  font-size: 120%;
  border: 1px solid #e2e2e2;
  padding-top: 16px;
}

.mme button.btn {
  color: #2a3e90;
  padding: 0px 5px;
}
.cal-price {
  color: red;
  margin-top: 7px;
}
.kJnpoy {
  padding: 15px;
  font-size: 140%;
}

.kJnpoy .leRpMz {
  padding-top: 2px;
}

body .text-primary {
  color: #0171bc !important;
}

/* a.list-group-item.list-group-item-action{
    color: #a2e5ff;
} */

#add-points .card-body,
#add-credits .modal-body {
  background: rgba(0, 0, 0, 0.03);
}

#add-points #field-wrapper {
  padding: 15px;
  font-size: 140%;
}
#add-points #field-wrapper label {
  padding-top: 5px;
}
a.list-group-item.list-group-item-action.is-active {
  color: #a2e5ff;
}

.react-confirm-alert-body {
  font-size: 20px;
}

.dispense-checkbox {
  margin-left: 5px;
  width: 20px;
  height: 20px;
  padding-top: 28px;
  vertical-align: middle;
}
body .dropdown-menu {
  padding: 0;
}
.react-confirm-alert-overlay.entereddate {
  z-index: 9999;
}

@media print {
  .no-print,
  .no-print * {
    display: none !important;
  }
}
.react-confirm-alert-body {
  word-break: break-word !important;
}

.dashboard .card.text-white .card-title a,
.dashboard .card.text-white .card-text a {
  color: #fff;
}

.dashboard .card.text-white .card-body {
  height: 135px;
}

.text-120 {
  font-size: 130%;
}

.initialInventory button:first-child {
  background-color: #51cf6e;
  zoom: 120%;
  font-weight: bold;
}
.initialInventory button:last-child {
  background-color: #c23826;
  zoom: 120%;
  font-weight: bold;
}

.react-confirm-alert-body h1 {
  font-size: 30px;
  word-break: break-word;
}
.react-confirm-alert-overlay {
  z-index: 9999 !important;
}

.dashboard .card.text-white .card-body {
  color: #fff;
}

.dashboard .card.text-white a:hover {
  text-decoration: none;
  /* box-shadow: 2px 2px #e2e2e2; */
}

.color-white a {
  color: #fff;
}

.term-condition {
  overflow-y: scroll;
  height: 500px;
}
#term-condition .modal-footer {
  display: block;
}
#term-condition input.form-check.float-left {
  margin-top: 10px;
  margin-right: 10px;
}

#term-condition p.float-left {
  margin-top: 8px;
}

button.btn.btn-primary a {
  color: #fff;
  text-decoration: none;
}

.pro-sidebar .pro-menu a,
.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item > .pro-item-content {
  font-weight: 400;
}
#main {
  margin-left: 250px;
  padding-left: 0;
}
.dashboard-controls .col-sm-6 .card {
  height: 300px;
}

.login-page {
  background-image: linear-gradient(
    140deg,
    #eadedb 0%,
    #7870bc 50%,
    #eadedb 85%
  );
}

.profile label {
  margin-top: 15px;
}

@media (min-width: 1400px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1320px !important;
  }
}

.landing-new .card .list-group-item {
  color: #1c75bb;
  cursor: pointer;
}

.landing-new .text-bg-primary {
  color: #fff !important;
  background-color: #2a3e90 !important;
}

.landing-new .font-size-18,
.landing-new .list-group-item {
  font-size: 18px;
}
.landing-new .card-header {
  font-size: 20px;
}
.text-body-secondary {
  --bs-text-opacity: 1;
  color: rgba(33, 37, 41, 0.75) !important;
}
.rounded-3 {
  border-radius: var(--bs-border-radius-lg) !important;
}
.font-red {
  color: red;
}
.text-dark {
  color: #000 !important;
}

.choose-plan-initial {
  font-size: 20px;
}
.choose-plan-initial input[type="checkbox"] {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.choose-plan-initial .col-4 {
  margin: 5px 0px;
}

.clear {
  clear: both;
}

.font-28 {
  font-size: 28px;
}

.confirm-plan .react-confirm-alert-button-group {
  display: block;
}

.react-confirm-alert-button-group > button {
  background-color: #2a3e90;
}
.confirm-plan .react-confirm-alert-button-group > button:last-child {
  float: right;
  background-color: green;
}
.grayed {
  opacity: 0.5;
}
.solutions .innerSol {
  background-color: #0071bc;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
}
.no-border {
  border: none !important;
}
.users .react-toggle {
  margin: -3px 8px;
}
table.users p {
  margin-top: 5px;
}
.pointer {
  cursor: pointer;
}
.password-wrapper {
  position: relative;
  margin-bottom: 20px;
}

.password-wrapper .eye-icon {
  position: absolute;
  right: 5px;
  top: 5px;
  cursor: pointer;
}
.growth .solutionWrapper {
  background-color: #c0c0c0;
  border-radius: 3px;
  padding: 5px 50px 5px 5px !important;
}
.premium .solutionWrapper {
  background-color: #ffd700;
  border-radius: 3px;
  padding: 5px 50px 5px 5px !important;
}
.addon .solutionWrapper {
  background-color: #cd7f23;
  border-radius: 3px;
  padding: 5px 50px 5px 5px !important;
}
.green-circle {
  width: 12px;
  float: left;
  height: 12px;
  border-radius: 7px;
  background: #03f703;
  margin-right: 10px;
}
.red-circle {
  width: 12px;
  float: left;
  height: 12px;
  border-radius: 7px;
  background: red;
  margin-right: 10px;
}

.xp24 {
  width: 750px;
  border: 1px solid #e2e2e2;
  padding: 17px;
  border-radius: 2px;
}

label {
  margin-bottom: 0rem;
  margin-top: 0.5rem;
}
div.input label {
  margin-bottom: 0.5rem;
  margin-top: 0rem;
}
.send-otp .form-check-input {
  margin-top: 0.8rem;
}
.sticktoright {
  float: left;
  margin-top: 10px;
}

.modal-lg {
  max-width: 850px !important;
}

.image-select {
  width: 200px;
}

.dashboard .card {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  border: none;
}

.dashboard .card:hover {
  animation-name: example;
  animation-duration: 0.5s;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.2), 0 10px 10px rgba(0, 0, 0, 0.33);
}

.saved-search .searchData {
  max-width: 500px;
  word-wrap: break-word;
}

.pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout,
.pro-sidebar .pro-menu a,
body .pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item {
  color: rgba(0, 0, 0, 0.54) !important;
  background-color: #fff;
}

body
  .pro-sidebar
  .pro-menu.square
  .pro-menu-item
  > .pro-inner-item
  > .pro-icon-wrapper {
  background-color: #fff;
}
body .pro-sidebar .pro-menu .pro-menu-item :hover {
  /* background-color: rgba(0, 0, 0, 0.04); */
  color: rgba(0, 0, 0, 0.54) !important;
}
body
  .pro-sidebar
  .pro-menu
  .pro-menu-item
  > .pro-inner-item
  > .pro-icon-wrapper {
  margin-right: 10px;
}
body
  .pro-sidebar
  .pro-menu
  .pro-menu-item
  > .pro-inner-item
  > .pro-icon-wrapper {
  width: auto;
}
body
  .pro-sidebar
  .pro-menu
  .pro-menu-item
  > .pro-inner-item
  > .pro-icon-wrapper {
  height: 28px;
}
a.list-group-item.list-group-item-action {
  padding: 0;
}
.pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout .pro-sidebar-footer {
  border-top: 1px solid rgba(173, 173, 173, 0.4);
}
li.pro-sub-menu :hover {
  background-color: #f5f3f3;
}
.pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item {
  background-color: #fff;
}
.react-slidedown.pro-inner-list-item :hover {
  background-color: #fff;
}
.pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout {
  border: 1px solid #e2e2e2;
}
li.pro-menu-item.pro-sub-menu .pro-inner-item {
  color: rgba(0, 0, 0, 0.54) !important;
}
li.pro-menu-item.pro-sub-menu.open > .pro-inner-item {
  background-color: #f5f3f3 !important;
}
.navbar li a.list-group-item {
  color: rgba(0, 0, 0, 0.54);
  padding: 0.5rem 1rem;
  text-decoration: none;
}
.contact-icon {
  width: 35px;
  border-radius: 18px;
}
.landing {
  background-color: #f3f5f8;
}
div#wrapper {
  min-height: 100vh;
}
.dashboard .counter-icon {
  border-radius: 50%;
  overflow: hidden;
  padding: 20px;
  background-color: rgb(210, 79, 35);
  max-height: 110px;
}
.dashboard .counter-icon svg {
  width: auto;
  height: auto;
}

/* Flip card */

.flip-card .card {
  position: relative;
  height: 200px;
  overflow: hidden;
  border: 5px solid #a72a96;
  transition: all 0.5s ease;
}

.flip-card .card .image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 40%;
  background-size: cover;
  background-position: center;
  transition: transform 0.5s ease;
}

.flip-card .card .content {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 60%;
  background-color: #f8f9fa;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  transition: height 0.5s ease;
}

.flip-card .card:hover .image {
  transform: translateY(-100%);
}

.flip-card .card:hover .content {
  height: 100%;
  font-size: 16px;
}

.flip-card .content .additional-text {
  display: none;
  opacity: 0;
  transition: opacity 0.5s ease;
}

.card:hover .content .additional-text {
  display: block;
  opacity: 1;
  font-size: 12px;
  text-align: left;
}
.gold-border .card {
  border: 9px solid #d4af37 !important;
}
.save20 {
  float: right;
  margin-top: -42px;
  margin-right: 6px;
  width: 80px;
}

h3.choosePlan {
  background: #d4af37;
  color: #3a3333;
  padding: 8px;
  text-transform: capitalize;
}
